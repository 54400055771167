<!-- 钱包 -->
<template>
  <div class="wallet-page">
    <div class="content">
      <div class="wallet-data">
        <div class="wallet-tip font-m">{{ $i18n.t("wal_account") }}(USDT)</div>
        <div class="main-data font-b">{{ userInfo.walletBalance | fixed_6 }}</div>
        <div class="data-list">
          <div class="data-item">
            <div class="word">{{ $i18n.t("wal_total_revenue") }}(USDT)</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ userInfo.returnsUSD | fixed_6 }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("wal_extracted") }}(USDT)</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ userInfo.withdrawedReturnsUSD | fixed_6 }}</div>
            </div>
          </div>
          <div class="data-item">
            <div class="word">{{ $i18n.t("wal_extracted") }}(USDT)</div>
            <div class="price">
              <img src="../assets/images/icon-eth-s.svg" alt="" />
              <div class="num font-b">{{ userInfo.withdrawableUSD | fixed_6 }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="wallet-handler" v-if="false">
        <div class="handler-item" @click="toPage('/recharge')">
          <div class="icon">
            <img src="../assets/images/icon-rechange.svg" alt="" />
          </div>
          <div class="word">{{ $i18n.t("wal_recharge") }}</div>
        </div>
        <div class="handler-item" @click="toPage('/exchange')">
          <div class="icon">
            <img src="../assets/images/icon-duihuan.svg" alt="" />
          </div>
          <div class="word">{{ $i18n.t("wal_exchange") }}</div>
        </div>
        <div class="handler-item" @click="toPage('/withdraw')">
          <div class="icon">
            <img src="../assets/images/icon-tixian.svg" alt="" />
          </div>
          <div class="word">{{ $i18n.t("wal_withdrawal") }}</div>
        </div>
      </div>
      <div class="record">
        <div class="record-title">
          <div class="word font-m">{{ $i18n.t("wal_record") }}</div>
          <router-link class="all" to="/record">
            {{ $i18n.t("wal_whole") }}
            <img src="../assets/images/icon-arraw-right.svg" alt="" />
          </router-link>
        </div>
        <div class="record-list">
          <div class="record-item" v-for="(item, index) in recordList" :key="index">
            <div class="record-type-line font-m">
              <div class="record-type">{{ item.remark }}</div>
            </div>
            <div class="record-rate font-m" v-for="(item1, index1) in item.changes" :key="index1">
              <div>{{ item1.type }}</div>
              <div>
                {{ (item1.after - item1.before) | fixed_6 }}
                {{ item1.type.includes("USD") ? "USDT" : "ETH" }}
              </div>
            </div>
            <div class="record-time">
              {{ dayjs(handleTime(item.createdAt)).format("YYYY-MM-DD HH:mm:ss") }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <tab-bar :currentIndex="currentTab" @changeTab="changeTab" /> -->
  </div>
</template>

<script>
// import TabBar from "@/components/TabBar.vue";
import dayjs from 'dayjs'
export default {
  name: "wallet",
  // components: {
  //   TabBar,
  // },
  data() {
    return {
      recordList: [],
      userInfo: {},
      currentTab: 3,
    };
  },
  filters: {
    fixed_6: function (value) {
      if (!value && value !== 0) return 0;
      return value.toFixed(6);
    },
  },
  created() {
    this.getUserInfo();
    this.getTransList();
  },
  methods: {
    dayjs,
    handleTime(date) {
      if (typeof date !== 'string') return date
      return date.replace(/-|\./g,"/").replace(' UTC', '')
    },
    getUserInfo() {
      let that = this;
      this.$http.getUserInfo().then((res) => {
        console.log(res);
        if (res.code === 0) {
          that.userInfo = res.data;
        }
      });
    },
    getTransList() {
      let that = this;
      this.$http
        .getTransList({
          page: 1,
          pageSize: 10,
        })
        .then((res) => {
          console.log(res);
          if (res.code === 0) {
            that.recordList = res.data.list;
          }
        });
    },
    changeTab(index) {
      this.currentTab = index;
    },
    toPage(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style lang="less">
.wallet-page {
  padding-top: 56px;
  padding-bottom: 0.8rem;
  min-height: 100vh;
  .content {
    padding: 0.48rem;
    .wallet-data {
      padding: 0.48rem 0;
      color: #fff;
      border-radius: 0.48rem;
      margin-bottom: 0.48rem;
      background: linear-gradient(90deg, #00cdff 0%, #32f5db 49%, #63ff92 100%);
      .wallet-tip {
        font-size: 0.3rem;
        line-height: 0.48rem;
      }
      .main-data {
        font-weight: bold;
        line-height: 0.72rem;
        font-size: 0.46rem;
      }
      .data-list {
        display: flex;
        justify-content: space-between;
        margin-top: 0.3rem;
        .data-item {
          text-align: center;
          .word {
            color: #fff;
          }
          .price {
            justify-content: center;
            display: flex;
            .num {
              color: #fff;
            }
          }
        }
      }
    }
    .wallet-handler {
      border: 1px solid #eeeeee;
      border-radius: 0.2rem;
      padding: 0.3rem 0.48rem;
      display: flex;
      margin-bottom: 0.48rem;
      .handler-item {
        margin-right: 0.8rem;
        .icon {
          display: flex;
          justify-content: center;
          img {
            width: 0.64rem;
            height: 0.64rem;
          }
        }
        .word {
          font-size: 0.28rem;
          color: #666;
          line-height: 0.4rem;
          margin-top: 0.1rem;
        }
      }
      .handler-item:last-child {
        margin-right: 0;
      }
    }
    .record {
      .record-title {
        display: flex;
        height: 0.6rem;
        justify-content: space-between;
        .word {
          line-height: 0.6rem;
          font-size: 0.4rem;
        }
        .all {
          color: #999;
          font-size: 0.28rem;
          line-height: 0.6rem;
          img {
            width: 0.32rem;
            height: 0.32rem;
            vertical-align: middle;
            transform: translateY(-2px);
          }
        }
      }
    }
  }
}
</style>

<style lang="less" scoped>
.record-type {
  word-break: break-all;
  text-align: left;
}
</style>
